html {
  box-sizing: border-box;
  font-size: calc(100vw / 1440 * 10);
  height: 100%;
  left: 0;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  -moz-osx-font-smoothing: grayscale;
  top: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;

  @include media("<=phone") {
    font-size: calc(100vw / 375 * 10);
  }
}

// resets
body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Segoe UI,
    sans-serif;
  font-size: 16px;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  width: 100%;
  background: $main-bg;
  font-weight: 500;
}

*,
*:before,
*:after {
  user-select: none;
  box-sizing: border-box;
  outline: none;
  -webkit-touch-callout: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

canvas {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 1s ease;
  width: 100%;
  z-index: 1;
}

.transition-modal {
  z-index: z("transition-modal");
  pointer-events: none;
}

button {
  cursor: pointer;
  color: inherit;
}
