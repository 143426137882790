%caption {
  font-size: 14px;
  line-height: 1;
  letter-spacing: calc(-0.01 * 14px);
  font-weight: 500;
}

%body {
  font-size: 14px;
  letter-spacing: calc(-0.015 * 14px);
  line-height: 1.3;
  font-weight: 500;
}

%body-16 {
  font-size: 16px;
  letter-spacing: calc(-0.015 * 16px);
  line-height: 1.3;
  font-weight: 500;
}

%title-1 {
  font-family: "Cabinet";
  font-size: 144px;
  line-height: 0.9;
  font-weight: 400;
  // letter-spacing: calc(-0.055 * 144px);
  letter-spacing: -0.055em;
  font-feature-settings: "aalt";

  @include media("<=tablet") {
    font-size: 64px;
  }
}

%title-2 {
  font-family: "Cabinet";
  font-size: 64px;
  line-height: 0.9;
  font-weight: 400;
  // letter-spacing: calc(-0.055 * 144px);
  letter-spacing: -0.055em;

  @include media("<=tablet") {
    font-size: 24px;
  }
}
