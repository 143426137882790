@font-face {
  font-family: "Cabinet";
  src: url("../fonts/CabinetGrotesk-Regular.woff2") format("woff2"),
    url("../fonts/CabinetGrotesk-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../fonts/Satoshi-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../fonts/Satoshi-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/Satoshi-MediumItalic.woff2") format("woff2"),
    url("../fonts/Satoshi-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
