.cases {
  @extend %page;

  &--active {
    @extend %page--active;
  }
}

.case {
  @extend %content;

  opacity: 0;
  padding: 15rem 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;
  height: 200vh;

  &--active {
    opacity: 1;
    transition: none;
    visibility: visible;
  }

  @include media("<=phone") {
    padding: 5rem 0;
  }
}
