.home {
  @extend %cover;
  @extend %page;
  opacity: 1;
  visibility: visible;

  transition: opacity 0.4s ease, visibility 0.4s ease;

  &--active {
    @extend %page--active;
  }
}

.home__wrapper {
  @extend %cover;
  height: auto;
  position: relative;
}

.home__nav {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  z-index: z("navigation");

  @include media("<=tablet") {
    padding: 0 16px;
    top: 16px;
  }

  &__link {
    @extend %button;
  }
}

.home__title {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  @extend %title-1;
  overflow: hidden;

  .word {
    @extend %title-1;

    padding-bottom: 0.2ch;
    margin-top: -0.2ch;

    display: inline-block;
    transform-origin: center center 0.5em;
  }

  @include media("<=tablet") {
    top: 88px;
  }

  &--first {
    z-index: z("content");
  }

  &--second {
    top: 170px;

    @include media("<=tablet") {
      top: 146px;
    }
  }

  &__link {
  }
}

.home__content {
  padding: 0 16px;
  padding-top: 364px;
  padding-bottom: 50px;

  @include media("<=tablet") {
    padding-top: 252px;
    padding-bottom: 100px;
  }
}

.image__wrapper {
  max-width: 482px;
  height: 632px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  opacity: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include media("<=450px") {
    max-width: 482px;
    height: 449px;
  }
}

.home__play__button {
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  width: 12rem;
  height: 12rem;
  @extend %body;

  @include media("<=tablet") {
    position: static;
    width: 100%;
    height: 48.4px;
    background: $mustard;
    border-top: 0.5px solid $dark-text;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }

  &:hover {
    .home__play__button__circle {
      animation-play-state: paused;
    }
  }

  &__circle {
    animation: rotate 30s linear infinite;
    transform-origin: center;
    svg {
      width: 100%;
      height: 100%;
    }

    textPath {
      fill: $dark-text;
      font-family: "Satoshi";
      @extend %body;
      font-size: 15px;
      text-transform: uppercase;
    }

    @include media("<=tablet") {
      display: none;
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &__text {
    @include media(">tablet") {
      display: none;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    @include media("<=tablet") {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__circle,
  &__icon {
    @include media(">tablet") {
      position: absolute;
    }
  }
}

.home__footer {
  position: relative;
  z-index: z("navigation");

  @include media("<=tablet") {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 16px;
  }
}

.home__quote {
  bottom: 43px;
  left: 40px;
  width: 100%;
  @extend %body;
  padding: 0 16px;

  @include media(">tablet") {
    max-width: 210px;
    position: fixed;
    padding: 0;
  }

  &__text {
    margin-bottom: 12px;
    font-style: italic;
  }

  &.desktop {
    @include media("<=tablet") {
      display: none;
    }
  }

  &.mobile {
    @include media(">tablet") {
      display: none;
    }
  }
}
