%page {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;
  width: 100%;
  // z-index: z('content');
}

%page--active {
  opacity: 1;
  pointer-events: auto;
  transition: none;
  visibility: visible;
}

%content {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

%wrapper {
  margin: auto;
  max-width: 130rem;
  width: 90%;

  @include media("<=phone") {
    width: calc(100% - 5rem);
  }
}

%button {
  @extend %caption;
  text-transform: uppercase;
  padding: 13px 16px;
  border: 0.5px solid $dark-text;
  border-radius: 32px;
  transition: transform 0.2s ease;
  background: $main-bg;

  &:hover {
    transform: scale(1.01);
  }
}
