.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: z("modal");
  transition: visibility 0s linear 0.2s, opacity 0.2s ease-in-out;
  will-change: opacity;
  background: $main-bg;

  &.active {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
  }

  &__close {
    top: 40px;
    right: 40px;
    position: absolute;
    @extend %button;

    @include media("<=tablet") {
      right: 20px;
    }
  }

  &__video {
    width: 100%;
    height: 100%;
    padding-left: 15.5rem;
    padding-right: 15.5rem;
    max-width: 1360px;
    margin: 0 auto;

    @include media("<=tablet") {
      padding: 0;
    }

    .c-video {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.c-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: #000;

  iframe {
    width: 100%;
    height: 100%;
  }

  &__cover,
  &__cover img,
  &__cover video,
  #yt-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__cover {
    img,
    video {
      object-fit: cover;
    }
  }
}
