.home__wrapper, .home, .preloader, .navigation {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
  font-family: inherit;
}

a {
  text-decoration: none;
  font-family: inherit;
}

html {
  box-sizing: border-box;
  font-size: 0.6944444444vw;
  height: 100%;
  left: 0;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  -moz-osx-font-smoothing: grayscale;
  top: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;
}

@media (max-width: 425px) {
  html {
    font-size: 2.6666666667vw;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Satoshi", -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Segoe UI, sans-serif;
  font-size: 16px;
  height: 100%;
  left: 0;
  opacity: 1;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  width: 100%;
  background: #f3f3f2;
  font-weight: 500;
}

*,
*:before,
*:after {
  user-select: none;
  box-sizing: border-box;
  outline: none;
  -webkit-touch-callout: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

canvas {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: opacity 1s ease;
  width: 100%;
  z-index: 1;
}

.transition-modal {
  z-index: 7;
  pointer-events: none;
}

button {
  cursor: pointer;
  color: inherit;
}

@font-face {
  font-family: "Cabinet";
  src: url("CabinetGrotesk-Regular.9d402eac.woff2") format("woff2"), url("CabinetGrotesk-Regular.581e521d.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("Satoshi-Regular.5cd7338f.woff2") format("woff2"), url("Satoshi-Regular.23214b96.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("Satoshi-Medium.5be63f72.woff2") format("woff2"), url("Satoshi-Medium.de33c0c3.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("Satoshi-MediumItalic.1ce24771.woff2") format("woff2"), url("Satoshi-MediumItalic.29954c4f.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

.cases, .home {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;
  width: 100%;
}

.cases--active, .home--active {
  opacity: 1;
  pointer-events: auto;
  transition: none;
  visibility: visible;
}

.case {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

.home__nav__link, .c-modal__close {
  text-transform: uppercase;
  padding: 13px 16px;
  border: 0.5px solid #191200;
  border-radius: 32px;
  transition: transform 0.2s ease;
  background: #f3f3f2;
}

.home__nav__link:hover, .c-modal__close:hover {
  transform: scale(1.01);
}

.home__nav__link, .c-modal__close {
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.14px;
  font-weight: 500;
}

.home__quote, .home__play__button__circle textPath, .home__play__button {
  font-size: 14px;
  letter-spacing: -0.21px;
  line-height: 1.3;
  font-weight: 500;
}

.home__title .word, .home__title {
  font-family: "Cabinet";
  font-size: 144px;
  line-height: 0.9;
  font-weight: 400;
  letter-spacing: -0.055em;
  font-feature-settings: "aalt";
}

@media (max-width: 768px) {
  .home__title .word, .home__title {
    font-size: 64px;
  }
}

.preloader__number, .preloader__text span span, .preloader__text {
  font-family: "Cabinet";
  font-size: 64px;
  line-height: 0.9;
  font-weight: 400;
  letter-spacing: -0.055em;
}

@media (max-width: 768px) {
  .preloader__number, .preloader__text span span, .preloader__text {
    font-size: 24px;
  }
}

.navigation {
  pointer-events: none;
  position: fixed;
  z-index: 6;
}

.navigation__item {
  pointer-events: auto;
}

.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 8;
  transition: visibility 0s linear 0.2s, opacity 0.2s ease-in-out;
  will-change: opacity;
  background: #f3f3f2;
}

.c-modal.active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.c-modal__close {
  top: 40px;
  right: 40px;
  position: absolute;
}

@media (max-width: 768px) {
  .c-modal__close {
    right: 20px;
  }
}

.c-modal__video {
  width: 100%;
  height: 100%;
  padding-left: 15.5rem;
  padding-right: 15.5rem;
  max-width: 1360px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .c-modal__video {
    padding: 0;
  }
}

.c-modal__video .c-video {
  top: 50%;
  transform: translateY(-50%);
}

.c-video {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: #000;
}

.c-video iframe {
  width: 100%;
  height: 100%;
}

.c-video__cover, .c-video__cover img, .c-video__cover video,
.c-video #yt-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-video__cover img,
.c-video__cover video {
  object-fit: cover;
}

.preloader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: #f3f3f2;
  color: #191200;
  z-index: 9;
  padding: 40px;
}

@media (max-width: 768px) {
  .preloader {
    padding: 16px;
  }
}

.preloader__inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.preloader__text {
  max-width: 688px;
  opacity: 0;
}

.preloader__text span span {
  display: inline-block;
  transform-origin: center center 0.5em;
}

.preloader__number__text {
  display: inline-block;
}

.home {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.4s ease, visibility 0.4s ease;
}

.home__wrapper {
  height: auto;
  position: relative;
}

.home__nav {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
  z-index: 6;
}

@media (max-width: 768px) {
  .home__nav {
    padding: 0 16px;
    top: 16px;
  }
}

.home__title {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.home__title .word {
  padding-bottom: 0.2ch;
  margin-top: -0.2ch;
  display: inline-block;
  transform-origin: center center 0.5em;
}

@media (max-width: 768px) {
  .home__title {
    top: 88px;
  }
}

.home__title--first {
  z-index: 5;
}

.home__title--second {
  top: 170px;
}

@media (max-width: 768px) {
  .home__title--second {
    top: 146px;
  }
}

.home__content {
  padding: 0 16px;
  padding-top: 364px;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .home__content {
    padding-top: 252px;
    padding-bottom: 100px;
  }
}

.image__wrapper {
  max-width: 482px;
  height: 632px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  opacity: 0;
}

.image__wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 450px) {
  .image__wrapper {
    max-width: 482px;
    height: 449px;
  }
}

.home__play__button {
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  width: 12rem;
  height: 12rem;
}

@media (max-width: 768px) {
  .home__play__button {
    position: static;
    width: 100%;
    height: 48.4px;
    background: #fec228;
    border-top: 0.5px solid #191200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
  }
}

.home__play__button:hover .home__play__button__circle {
  animation-play-state: paused;
}

.home__play__button__circle {
  animation: rotate 30s linear infinite;
  transform-origin: center;
}

.home__play__button__circle svg {
  width: 100%;
  height: 100%;
}

.home__play__button__circle textPath {
  fill: #191200;
  font-family: "Satoshi";
  font-size: 15px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .home__play__button__circle {
    display: none;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 769px) {
  .home__play__button__text {
    display: none;
  }
}

.home__play__button__icon {
  width: 32px;
  height: 32px;
}

@media (max-width: 768px) {
  .home__play__button__icon {
    width: 24px;
    height: 24px;
  }
}

.home__play__button__icon svg {
  width: 100%;
  height: 100%;
}

@media (min-width: 769px) {
  .home__play__button__circle, .home__play__button__icon {
    position: absolute;
  }
}

.home__footer {
  position: relative;
  z-index: 6;
}

@media (max-width: 768px) {
  .home__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 16px;
  }
}

.home__quote {
  bottom: 43px;
  left: 40px;
  width: 100%;
  padding: 0 16px;
}

@media (min-width: 769px) {
  .home__quote {
    max-width: 210px;
    position: fixed;
    padding: 0;
  }
}

.home__quote__text {
  margin-bottom: 12px;
  font-style: italic;
}

@media (max-width: 768px) {
  .home__quote.desktop {
    display: none;
  }
}

@media (min-width: 769px) {
  .home__quote.mobile {
    display: none;
  }
}

.case {
  opacity: 0;
  padding: 15rem 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
  visibility: hidden;
  height: 200vh;
}

.case--active {
  opacity: 1;
  transition: none;
  visibility: visible;
}

@media (max-width: 425px) {
  .case {
    padding: 5rem 0;
  }
}
/* Recommended styles for Splitting */
.splitting .word,
.splitting .char {
  display: inline-block;
}

/* Psuedo-element chars */
.splitting .char {
  position: relative;
}

/**
 * Populate the psuedo elements with the character to allow for expanded effects
 * Set to `display: none` by default; just add `display: block` when you want
 * to use the psuedo elements
 */
.splitting .char::before,
.splitting .char::after {
  content: attr(data-char);
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  transition: inherit;
  user-select: none;
}

/* Expanded CSS Variables */

.splitting {
  /* The center word index */
  --word-center: calc((var(--word-total) - 1) / 2);

  /* The center character index */
  --char-center: calc((var(--char-total) - 1) / 2);

  /* The center character index */
  --line-center: calc((var(--line-total) - 1) / 2);
}

.splitting .word {
  /* Pecent (0-1) of the word's position */
  --word-percent: calc(var(--word-index) / var(--word-total));

  /* Pecent (0-1) of the line's position */
  --line-percent: calc(var(--line-index) / var(--line-total));
}

.splitting .char {
  /* Percent (0-1) of the char's position */
  --char-percent: calc(var(--char-index) / var(--char-total));

  /* Offset from center, positive & negative */
  --char-offset: calc(var(--char-index) - var(--char-center));

  /* Absolute distance from center, only positive */
  --distance: calc(
     (var(--char-offset) * var(--char-offset)) / var(--char-center)
  );

  /* Distance from center where -1 is the far left, 0 is center, 1 is far right */
  --distance-sine: calc(var(--char-offset) / var(--char-center));

  /* Distance from center where 1 is far left/far right, 0 is center */
  --distance-percent: calc((var(--distance) / var(--char-center)));
}

.splitting.cells img { width: 100%; display: block; }

@supports ( display: grid ) {
  .splitting.cells {
    position: relative;
    overflow: hidden;
    background-size: cover;
    visibility: hidden;
  }

  .splitting .cell-grid {
    background: inherit;
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
    display: grid;
    grid-template: repeat( var(--row-total), 1fr ) / repeat( var(--col-total), 1fr );
  }

  .splitting .cell {
    background: inherit;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-inner {
    background: inherit;
    position: absolute;
    visibility: visible;
    /* Size to fit the whole container size */
    width: calc(100% * var(--col-total));
    height: calc(100% * var(--row-total));
    /* Position properly */
    left: calc(-100% * var(--col-index));
    top: calc(-100% * var(--row-index));
  }

  /* Helper variables for advanced effects */
  .splitting .cell {
    --center-x: calc((var(--col-total) - 1) / 2);
    --center-y: calc((var(--row-total) - 1) / 2);

    /* Offset from center, positive & negative */
    --offset-x: calc(var(--col-index) - var(--center-x));
    --offset-y: calc(var(--row-index) - var(--center-y));

    /* Absolute distance from center, only positive */
    --distance-x: calc( (var(--offset-x) * var(--offset-x)) / var(--center-x) );

    /* Absolute distance from center, only positive */
    --distance-y: calc( (var(--offset-y) * var(--offset-y)) / var(--center-y) );
  }
}


/*# sourceMappingURL=index.852aac16.css.map */
