.preloader {
  @extend %cover;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: $main-bg;
  color: $dark-text;
  z-index: z("preloader");
  padding: 40px;

  @include media("<=tablet") {
    padding: 16px;
  }

  &__inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.preloader__text {
  @extend %title-2;
  max-width: 688px;
  opacity: 0;

  span span {
    @extend %title-2;

    display: inline-block;
    transform-origin: center center 0.5em;
  }
}

.preloader__number {
  @extend %title-2;
}

.preloader__number__text {
  display: inline-block;
}
